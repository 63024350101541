<template>
  <div v-if="props.showModal" class="modal-overlay">
    <div class="modal-content">
      <!-- <p class="modal-title">Up to 10 chat rooms per group</p> -->
      <p v-if="props.buttonType === 'save'" class="modal-subtitle">레이아웃 설정을 저장 하시겠습니까?</p>
      <p v-if="props.buttonType === 'distribution'" class="modal-subtitle">저장된 레이아웃 설정을 <br/>
        배포 하시겠습니까?</p>
      <p v-if="props.buttonType === 'notSaved'" class="modal-subtitle">레이아웃을 저장한 후<br/>배포 할 수 있습니다.</p>
      
      <div class="modal-footer-new">
        
        <button class="modal-button p-button-secondary" @click="check">확인</button>
        <button v-if="props.buttonType !== 'notSaved'" class="p-button p-component p-button-secondary" @click="closeModal">취소</button>
      </div>
      
      
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
const props = defineProps({
  showModal: {
    type: Boolean,
    required: true,
  },
  buttonType: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close', 'accept']);

const closeModal = () => {
  emit('close');
};

const check = () => {
  emit('accept');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-subtitle {
  font-size: 14px;
  margin-bottom: 20px;
}

.modal-button {
  background-color: #3c6efb;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #2b50e3;
}
.modal-footer-new{
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>